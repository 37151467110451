import React, { useState } from 'react';
import './Playlist.scss';

const Playlist = ({ songs, title }) => { // Receive the title prop
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = React.createRef();

  const playPauseHandler = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const songEndedHandler = () => {
    if (currentSongIndex < songs.length - 1) {
      setCurrentSongIndex(currentSongIndex + 1);
    } else {
      setCurrentSongIndex(0);
    }
  };

  return (
    <div className="playlist-container">
      <h2 className="playlist-title">{title}</h2> {/* Display the playlist title */}
      <div className="song-info">
        <p>Now Playing: {songs[currentSongIndex].title}</p>
        <button className="play-button" onClick={playPauseHandler}>{isPlaying ? 'Pause' : 'Play'}</button>
      </div>
      <audio
        ref={audioRef}
        src={songs[currentSongIndex].url}
        onEnded={songEndedHandler}
      />
    </div>
  );
};

export default Playlist;
