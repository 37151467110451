import logo from './logo.svg';
import './App.scss';
import Playlist from './Playlist';
import song1 from './audio/song1.mp3';
import song2 from './audio/song2.mp3';
import song3 from './audio/song3.mp3';
import song4 from './audio/song4.mp3';
import song5 from './audio/song5.mp3';


function App() {
  const pianoPlaylistTitle = "Lunes de Piano";
  const salsaPlaylistTitle = "Martes de Salsa";
  const rockPlaylistTitle = "Miércoles de Rock";

  const pianoSongs = [
    {
      title: 'Nuvole Bianche - Ludovico Einaudi',
      url: song1
    },
    {
      title: 'Braveheart - Jacobs Piano',
      url: song2
    },
  ];

  const salsaSongs = [
    {
      title: 'Conteo Regresivo - Gilberto Santarosa',
      url: song3
    },
    {
      title: 'Corazon de hierro - Yiyo Sarante',
      url: song4
    },
  ];

  const rockSongs = [
    {
      title: 'The Cranberries - Zombie',
      url: song5
    }
  ];

  return (
    <div className="App">
      <Playlist songs={pianoSongs} title={pianoPlaylistTitle} />
      <Playlist songs={salsaSongs} title={salsaPlaylistTitle} />
      <Playlist songs={rockSongs} title={rockPlaylistTitle} />
    </div>
  );
}

export default App;
